import qs from 'qs';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useToken } from '../auth/TokenProvider';
import { useIsGuest } from '../auth/UserProvider';
import { useTranslate } from '../i18n/LanguageProvider';
import { toastError, toastInfo, toastSuccess } from '../notifications';

const ApiContext = createContext(() => {});

export const ApiProvider = props => {
  const translate = useTranslate();
  const openToastId = useRef();
  const [longRunningRequests, setLongRunningRequests] = useState(0);
  const hasLongRunningRequests = longRunningRequests > 0;

  useEffect(() => {
    if (hasLongRunningRequests) {
      openToastId.current = toastInfo(translate('api.operationPending'));
    }
    if (!hasLongRunningRequests && openToastId.current) {
      setTimeout(() => {
        toast.dismiss(openToastId.current);
        openToastId.current = null;
      }, 2000);
    }
  }, [hasLongRunningRequests]);

  return <ApiContext.Provider {...props} value={setLongRunningRequests} />;
};

export const useFetch = () => {
  const [token] = useToken();
  const isGuest = useIsGuest();
  const translate = useTranslate();
  const setLongRunningRequests = useContext(ApiContext);

  return useCallback(
    (path, options = {}) => {
      const {
        body,
        successNotification,
        warningNotification,
        errorNotification = translate('api.operationFailed'),
        headers: originalHeaders = {},
        ...otherOptions
      } = options;

      const headers = {
        authorization: token && !isGuest && `Basic ${token}`,
        ...originalHeaders,
      };

      const finalOptions = {
        headers,
        ...otherOptions,
      };

      if (body instanceof FormData) {
        finalOptions.body = body;
        finalOptions.method = finalOptions.method || 'POST';
      } else if (body) {
        headers['content-type'] = 'application/json';
        finalOptions.body = JSON.stringify(body);
        finalOptions.method = finalOptions.method || 'POST';
      }

      let timerExpired = false;
      const timer = setTimeout(() => {
        timerExpired = true;
        setLongRunningRequests(c => c + 1);
      }, 2000);

      return fetchFromApi(path, finalOptions)
        .then(result => {
          if (result?.calculationProblem) {
            toastError(warningNotification);
          } else if (successNotification) {
            toastSuccess(successNotification);
          }
          clearTimeout(timer);
          if (timerExpired) setLongRunningRequests(c => c - 1);
          return result;
        })
        .catch(error => {
          return error
            .clone()
            .json()
            .then(res => {
              const errorMessage = translate(`error.${res?.code || ''}`) || errorNotification;
              if (errorMessage) {
                toastError(errorMessage);
              }
              clearTimeout(timer);
              if (timerExpired) setLongRunningRequests(c => c - 1);
              throw error;
            });
        });
    },
    [token, isGuest]
  );
};

const fetchFromApi = (path, { queryParams, ...options } = {}) =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}${path}${queryParams ? `?${stringify(queryParams)}` : ''}`, options)
    .then(isOk)
    .then(async response => {
      try {
        const json = await toJson(response);
        return extractData(json);
      } catch (e) {}
    });

const stringify = queryParams =>
  qs.stringify(queryParams, {
    arrayFormat: 'comma',
  });

const isOk = async response => (response.ok ? response : Promise.reject(response));
const toJson = response => response.json();
const extractData = result => result.data || result;
